<template>
  <div class="productChart-container">
    <CChartBar
        :datasets="datasets"
        :labels="labels"
        :options="options"
    ></CChartBar>
  </div>
</template>

<script>
import { CChartBar } from '@coreui/vue-chartjs'

import {
  apiCall,
} from "../../../common/utils";
import moment from "moment";
// datasets: Array<object>
// labels: [string, Array<string>]
// options: object
// plugins: Array<any>

export default {
    name: 'MultiBarGraph',
    components: {
        CChartBar
    },
    props: {
        baseDate: {
            type: String,
            default: `${moment().locale('ko').format('YYYY-MM')}`
        }
    },
    data: function () {
        return {
            fromDate: "",
            toDate: "",
            datasets: [
                {   // 제품별 '신규' 사용자 판매 개수
                    borderWidth: 1, // 테두리 넓이
                    label: '신규',
                    data: [10, 20, 30, 20],
                    backgroundColor: 'rgb(255, 221, 0, 0.4)',
                    pointBackgroundColor: 'rgb(255, 221, 0)',
                    fill: true, // 채우기
                    tension: 0.1,
                    borderColor: 'rgb(255, 221, 0)',
                    pointBorderColor: 'rgb(255, 221, 0)',
                    pointBorderWidth: 1,
                },
                {   // 제품별 '기존' 사용자 판매 개수
                    borderWidth: 1, // 테두리 넓이
                    label: '기존',
                    data: [10, 20, 30, 20],
                    backgroundColor: 'rgb(0, 110, 255, 0.4)',
                    pointBackgroundColor: 'rgb(0, 110, 255)',
                    fill: true, // 채우기
                    tension: 0.1,
                    borderColor: 'rgb(0, 110, 255)',
                    pointBorderColor: 'rgb(0, 110, 255)',
                    pointBorderWidth: 1,
                }
            ],
            labels: ["A","B","C", "D"], // 제품코드
            options: {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    // x축 옵션
                    xAxes: [
                        {
                        stacked: false, // 쌓임
                        display: true, // x 축 show
                        scaleLabel: {
                            display: true,
                            labelString: '제품명',
                        },
                        grid: {
                            drawBorder: false,
                            color: '#323232',
                            lineWidth: 1,
                        },
                        ticks: {
                            beginAtZero: true, // 0부터 시작
                            padding: 0,
                        },
                        },
                    ],
                    // y축 옵션
                    yAxes: [
                        {
                        stacked: false, // 쌓임
                        display: true, // y 축 show
                        ticks: {
                            stepSize: 10, // 증가범위
                            beginAtZero: true,
                            // max: 60, // 최대범위
                            min: 0, // 최소범위
                            padding: 10, // 오른쪽 간격
                        },
                        grid: {
                            drawBorder: false,
                            color: '#323232',
                            lineWidth: 1,
                        },
                        },
                    ],
                },
            },
        }
    },
    watch: {
        baseDate: "prdOrders",
    },
    async created() {
        this.prdOrders();
    },
    methods: {
        async prdOrders() {
            this.fromDate = `${this.baseDate}-01`;

            const year = moment(this.fromDate).year();
            const month = moment(this.fromDate).month();
            const lastDay = moment().year(year).month(month).endOf('month').date();

            this.toDate = `${this.baseDate}-${lastDay}`;

            try {
                const qs = `fromDate=${this.fromDate}&toDate=${this.toDate}`;
                const r = await apiCall('GET', `/admin/api/dashboard/orders-division?${qs}`);
                // console.log("orders-division result ========> ", r.result);

                let newMember = [];
                let existMember = [];
                let prdName = [];
                for(const code of r.result.codeList) {
                    newMember.push(r.result[code].new);
                    existMember.push(r.result[code].exist);
                    prdName.push(r.result[code].prdName);
                }

                this.datasets[0].data = newMember;
                this.datasets[1].data = existMember;
                this.labels = prdName;

            } catch (error) {
                console.error("Product Order Graph Error ==========> ", error);
            }
        },
    }
}
</script>

<style>

.productChart-container > div > canvas {
    height: 300px;
}

</style>